<template lang="pug">
.review-card
  .stars
    i.fas.fa-star.star.filled(v-for="star in block.amount")
    i.fas.fa-star.star(v-for="star in (5 - block.amount)")
  .content-wrapper
    .content(
      :class="{ 'content--more' : readMore }"
      v-html="block.content"
    )
    .content-read-more(
      @click="readMore = !readMore"
    ) {{ !readMore ? 'Lees meer' : 'Minder' }}
  .review-card-footer
    h3.review-card-footer--name {{block.h1}}
    .review-card-footer--place {{block.h2}}
</template>

<script>
export default {
  name: 'ReviewCard',
  props: {
    block: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      readMore: false,
    };
  },
};
</script>

<style lang="scss">
.review-card {
  display: flex;
  flex-direction: column;
  padding: 35px;
  border-radius: $radius;
  background: $white;
  box-sizing: border-box;
  transition: all 0.2s 0s ease-in-out;

  .stars {
    padding: 20px 0;
  }

  .star {
    color: $lightGray;
    font-size: 18px;

    &:not(:first-child), &:not(:last-child) {
      margin: 0 5px;
    }

    &.filled {
      color: $grey90;
    }
  }

  .content-wrapper {
    position: relative;
    padding-bottom: 30px;
  }

  .content-read-more {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 60px;
    font-weight: bold;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: left;
    cursor: pointer;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,0.804359243697479) 44%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,0.804359243697479) 44%, rgba(255,255,255,0) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,0.804359243697479) 44%, rgba(255,255,255,0) 100%);
  }

  .content {
    font-size: 16px;
    line-height: 27px;
    height: 260px;
    overflow: hidden;

    transition: all 0.2s 0s ease-in-out;

    &--more {
      height: 100%;
      transition: all 0.2s 0s ease-in-out;
    }
  }

  &-footer{
    vertical-align: bottom;
    margin-top: auto;
    color: $hunterGreen;

    &--name {
      margin-bottom: 5px;
    }

    &--place {
      font-size: 18px;
      line-height: 30px;
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    width: calc(100% - 80px);
  }
}
</style>
